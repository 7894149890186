<template>
  <div class="resume" v-loading="loading">
    <div class="flex p-28">
      <!-- <el-avatar :src="Resume.Photo" :size="72"> </el-avatar> -->
      <div class="ml-24 u-flex-1 flex-column mr-24">
        <div>
          <span class="fs-20">{{ Resume.DisplayName }}</span>
        </div>
        <!-- <div class="mt-12 fs-12">
              <span>{{ formartDate(Resume.BirthDay, "yyyy-MM") }}</span>
            </div> -->
        <el-row class="mt-8">
          <el-col :span="8">
            <span>期望职位：{{ Resume.ExpectJobName }}</span>
          </el-col>
          <el-col :span="8">
            <span
              >期望薪资：{{ Resume.SalaryMin }}-{{ Resume.SalaryMax }}K</span
            >
          </el-col>
          <el-col :span="8">
            <span>期望地点：{{ Resume.ExpectWorkPlace }}</span>
          </el-col>
        </el-row>
        <el-row class="mt-8">
          <el-col :span="8">
            <span>联系电话：{{ Resume.ContactNumber }}</span>
          </el-col>
          <el-col :span="10">
            <span>联系邮箱：{{ Resume.Email }}</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="dialog-content">
      <span class="content-title">基本信息</span>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="info-item text-over">
            <span class="item-type">性别：</span
            ><span class="item-txt">{{ Resume.GenderDescription }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info-item text-over">
            <span class="item-type">出生年月：</span
            ><span class="item-txt">{{
              formartDate(Resume.BirthDay, "yyyy-MM")
            }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info-item text-over">
            <span class="item-type">籍贯：</span
            ><span class="item-txt">{{ Resume.NativePlace }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info-item text-over">
            <span class="item-type">婚姻状况：</span
            ><span class="item-txt">{{ Resume.MaritalStatus }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info-item text-over">
            <span class="item-type">政治面貌：</span
            ><span class="item-txt">{{ Resume.PoliticalStatus }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info-item text-over">
            <span class="item-type">最高学历：</span
            ><span class="item-txt">{{ Resume.EducationDescription }}</span>
          </div>
        </el-col>
        <!-- <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">联系电话：</span><span class="item-txt">{{ Resume.ContactNumber }}</span>
            </div>
          </el-col> -->
        <el-col :span="12">
          <div class="info-item">
            <span class="item-type">现住地址：</span
            ><span class=" present-address">{{ Resume.PresentAddress }}</span>
          </div>
        </el-col>
      </el-row>
      <!-- <span class="content-title">求职意向</span>
        <el-row :gutter="20" v-for="item in Resume.ResumeExpectDtoList" :key="item.Id">
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">岗位：</span><span class="item-txt">{{ item.ExpectJobName }}</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="info-item text-over">
              <span class="item-type">城市：</span><span class="item-txt">{{ item.ExpectWorkPlace }}</span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="info-item text-over">
              <span class="item-type">薪资：</span><span class="item-txt">{{ item.SalaryMin }}k-{{ item.SalaryMax }}k</span>
            </div>
          </el-col>
        </el-row> -->
      <span class="content-title">工作经历</span>
      <el-row
        :gutter="20"
        v-for="item in Resume.ResumeCareerDtoList"
        :key="item.Id"
      >
        <el-col :span="24">
          <div class="info-item text-over flex u-row-between">
            <span class="item-txt color-base fs-16">{{ item.JobName }}</span>
            <span class="item-txt color-light fs-14">
              {{ formartDate(item.StartTime, "yyyy-MM-dd") }} 至
              {{ formartDate(item.EndTime, "yyyy-MM-dd") }}
            </span>
          </div>
        </el-col>
        <el-col :span="24">
          <div class="info-item text-over">
            <span class="item-type">描述：</span
            ><span class="item-txt">{{ item.Description }}</span>
          </div>
        </el-col>
      </el-row>
      <span class="content-title">自我评价：</span>
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="info-item text-over">
            <span class="item-txt">{{ Resume.Introduction }}</span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api/resume";
import { dateTimeFilter } from "@/filters";
export default {
  props: {
    id: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      Resume: {
        ResumeCareerDtoList: []
      },
      loading: false
    };
  },
  created() {
    if (this.id) {
      this.init(this.id);
    }
  },
  computed: {
    formartDate() {
      return (data, formate) => dateTimeFilter(data, formate);
    }
  },
  methods: {
    init(id) {
      this.loading = true;
      getInfo(id).then(({ Result }) => {
        this.Resume = Object.assign({}, Result);
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.resume {
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.talent-sub {
  display: flex;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
  // font-size: 14px;
  color: #999;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
  .avatar {
    position: relative;
    align-self: flex-start;
    &-gender {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      font-size: 16px;
      padding: 2px;
      border-radius: 50%;
      &-male {
        background: #557df7;
      }
      &-female {
        background: #ff6677;
      }
    }
  }
  .info {
    flex: 1;
    margin-left: 19px;
  }
  .btns {
    margin-left: 120px;
  }
}
.dialog-content {
  // min-height: 400px;
  display: flex;
  padding: 10px 28px;
  flex-direction: column;
  // overflow-y: auto;
  .content-title {
    margin-bottom: 16px;
    &::before {
      content: "";
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #598DF3;
      margin-right: 5px;
    }
  }
}
.info-item {
  display: flex;
  // justify-content: space-between;
  align-items: baseline;
  padding: 10px 5px;
  .item-type {
    color: #999;
  }
}
.item-txt {
  white-space: break-spaces;
}
</style>
<style lang="scss">
// .resume {
//   .el-dialog__header {
//     background: linear-gradient(360deg, #14a495 0%, #598DF3 100%);
//     color: #fff;
//     .resume-avatar-gender {
//       color: #fff;
//       font-size: 16px;
//       padding: 2px;
//       margin-left: 8px;
//       border-radius: 50%;
//       &-male {
//         background: #557df7;
//       }
//       &-female {
//         background: #ff6677;
//       }
//     }
//   }
// }
</style>
