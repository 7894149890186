import { UserTypeEnum } from '@/utils/constant';
import request from '@/utils/request'
const controller = '/Chat'


const getChatGroupList = _ =>
  request({
    url: `${controller}/GetChatGroupList`,
    method: 'get'
  });

const getMessagePageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: 'get',
    params
  });

const sendMessage = data =>
  request({
    url: `${controller}/SendMessage`,
    method: 'post',
    data
  });

const markRead = id =>
  request({
    url: `${controller}/MarkRead`,
    method: 'post',
    params: { groupId: id }
  });

const generateChatGroup = param =>
  request({
    url: `${controller}/GenerateChatGroup`,
    method: 'post',
    data: {
      ...param,
      //pc端默认都是招聘者
      UserType:UserTypeEnum.JobRecruiter
    }
  });
  const getChatGroup = id =>
  request({
    url: `${controller}/GetChatGroup`,
    method: 'get',
    params: { id }
  });

const getResume = groupId =>
  request({
    url: `${controller}/GetResume?groupId=${groupId}`,
    method: 'get',
  });

export {
  getChatGroupList,
  getMessagePageList,
  sendMessage,
  markRead,
  generateChatGroup,
  getChatGroup,
  getResume
}