<template>
  <div class="page-chat" v-loading="loading">
    <lemon-imui ref="IMUI" width="100%" height="calc(100vh - 65px - 70px)" :user="user" :avatar-cricle="true" :hide-message-name="true" :hide-menu-avatar="true" :hide-menu="true" :drawer-option="drawerOption"
      :hide-drawer="false" loadend-text="" @pull-messages='handlePullMessages' @send="handleSend" @change-contact="handleChangeContact" @message-click="handleMessageClick">

      <template #cover>
        <div class="cover">
          <i class="el-icon-chat-dot-round" />
        </div>
      </template>

      <template #message-title="contact">
        <div class="message-title">
          <span>{{ contact.displayName }}</span>
          <div>
            <span v-if="contact.JobName" style="margin-right:10px;">当前沟通岗位：{{contact.JobName}}</span>
            <el-button type="primary" plain size="mini" @click="inviteOrViewDelivery(contact, $refs.IMUI)">简历</el-button>
          </div>
        </div>
      </template>

      <template #editor-footer>
        使用 ctrl + enter 快捷发送消息
      </template>

    </lemon-imui>

    <el-dialog :title="`${currentContactName}—邀请投递岗位`" :visible.sync="inviteDialogVisible" width="500px">
      <h3>该用户还未投递简历，邀请投递吧！</h3>
      <el-select v-model="inviteJobId" placeholder="请选择岗位" style="width:100%">
        <el-option v-for="item in jobOption" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="inviteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmInvite">确认邀请</el-button>
      </div>
    </el-dialog>

    <el-dialog title="简历详情" :visible.sync="resumeDialogVisible" width="1000px" :close-on-press-escape="false">
      <resume v-if="currentContact.ResumeId" :id="currentContact.ResumeId" />
    </el-dialog>

    <el-dialog title="岗位详情" :visible.sync="jobDialogVisible" width="1000px" :close-on-press-escape="false">
      <job-form v-if="recruitJobId" :id="recruitJobId" :isView="true" />
    </el-dialog>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getChatGroupList, getMessagePageList, getResume, markRead, sendMessage } from "@/api/chat";
import { ChatMessageTypeEnum, ChatMessageTypeStringEnum, MessageStatusEnum, MessageStatusStringEnum } from '@/utils/constant';
import { getMyList as getMyRecruitJobList } from '@/api/job';
import JobForm from '@/views/job/components/JobForm.vue'
import Resume from './resume.vue';

let IMUI;
export default {
  components: { JobForm, Resume },
  data() {
    return {
      loading: true,
      currentContact: {},
      currentChatId: undefined,
      drawerOption: {
        width: '200px',
        height: '200px',
        click: () => { },
        position: 'rightInside',

      },
      messageQueryParam: {
        PageIndex: 1,
        PageSize: 20
      },
      messageList: [],
      jobOption: [],
      inviteJobId: '',
      recruitJobId: '',
      inviteDialogVisible: false,
      jobDialogVisible: false,
      resumeDialogVisible: false
    };
  },
  beforeDestroy() {
    const { IMUI } = this.$refs;
    IMUI.clearMessages();
    this.messageList = [];
  },
  async mounted() {
    IMUI = this.$refs.IMUI;
    IMUI.initEditorTools([]);
    try {
      const { Result } = await getChatGroupList();
      //初始化表情包。
      // IMUI.initEmoji(...);
      //从后端请求联系人数据，包装成下面的样子
      const contacts = Result.map(item => ({
        ...item,
        //todo:记录当前分页的页码
        pageIndex: 1,

        id: item.Id,
        displayName: item.Name,
        avatar: item.AvatarFullPath,
        index: '联系人',
        unread: item.UnReadCount,
        //最近一条消息的内容，如果值为空，不会出现在“聊天”列表里面。
        //lastContentRender 函数会将 file 消息转换为 '[文件]', image 消息转换为 '[图片]'，对 text 会将文字里的表情标识替换为img标签,
        //todo:联系人列表暂时没有最后消息的类型，此处默认统一处理为text
        lastContent: IMUI.lastContentRender({ type: ChatMessageTypeStringEnum[ChatMessageTypeEnum.text], content: item.LastChatMessage || ' ' }),
        //最近一条消息的发送时间
        lastSendTime: new Date(item.LastChatTime).getTime(),
      }));

      IMUI.initContacts(contacts);
      IMUI.setLastContentRender('job', message => {
        return <span>[岗位]</span>
      });
      IMUI.setLastContentRender('interview', message => {
        return <span>[面试邀请]</span>
      });
      IMUI.setLastContentRender('resume', message => {
        return <span>[简历]</span>
      });
      this.loading = false;
      if (this.$route.params.ChatGroupId) {
        const contact = contacts.find(item => item.id === this.$route.params.ChatGroupId);
        if (contact) {
          IMUI.changeContact(contact.id);
        }
      }
      this.getMyRecruitJob();
    } catch (error) {
      this.$message.error('加载失败')
    }
  },
  computed: {
    ...mapState('chat', ['failedMessageList']),
    ...mapState('oidc', {
      user: ({ user } = {}) => {
        return {
          id: user.Id,
          displayName: user.NickName,
          avatar: user.Avatar
        }
      }
    }),
    currentContactName({ currentContact: { displayName } }) {
      return displayName || '';
    },
    inviteJobName({ jobOption = [], inviteJobId }) {
      const job = jobOption.find(item => item.value === inviteJobId);
      return job ? job.label : '';
    }
  },
  watch: {
    "$store.getters.receivedMessageList": {
      async handler(newMessageList, old) {
        if (newMessageList && newMessageList.length > 0) {
          if (IMUI) {
            newMessageList.forEach(item => {
              const message = {
                ...item,
                id: item.Id,
                type: ChatMessageTypeStringEnum[item.Type],
                content: item.Message,
                status: MessageStatusStringEnum[MessageStatusEnum.succeed],
                sendTime: new Date(item.CreationTime).getTime(),
                toContactId: item.ChatGroupId,
                fromUser: {
                  id: item.SendUserId,
                  displayName: item.Name,
                  avatar: item.SendUserId === this.user.id ? this.user.avatar : item.AvatarFullPath,
                }
              };
              IMUI.appendMessage(message, true);
            });
            if (this.currentContact) {
              IMUI.updateContact({ id: this.currentContact.id, unread: 0 })
              markRead(this.currentContact.id);
            }
            this.clearReceivedMessage();
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("chat", ['clearReceivedMessage', 'addFailedMessage', 'removeFailedMessage']),
    async loadMoreMessage(contact) {
      this.messageQueryParam.PageIndex = contact.pageIndex;
      this.messageQueryParam.ChatGroupId = contact.id;
      try {
        const { Result: { Data = [], TotalCount } } = await getMessagePageList(this.messageQueryParam);
        return {
          messages: Data.reverse().map(item => ({
            ...item,
            id: item.Id,
            status: MessageStatusStringEnum[item.Status],
            type: ChatMessageTypeStringEnum[item.Type],
            sendTime: new Date(item.CreationTime).getTime(),
            content: item.Message,
            toContactId: contact.id,
            fromUser: {
              //如果 id == this.user.id消息会显示在右侧，否则在左侧
              id: item.SendUserId,
              displayName: item.Name,
              avatar: item.SendUserId === this.user.id ? this.user.avatar : item.AvatarFullPath,
            }
          })),
          total: TotalCount
        };
      } catch (error) {
        return {};
      }
    },
    async handlePullMessages(contact, next, instance) {
      //todo:如果会话组已经加载完了，则无须再加载
      if (contact.loadEnd) {
        return next([], true);
      }
      const currentPageIndex = contact.pageIndex;
      instance.updateContact({ id: contact.id, pageIndex: contact.pageIndex + 1 });
      let { messages, total } = await this.loadMoreMessage(contact);
      if (!messages) {
        this.$message.error('加载失败');
        return next([], true);
      }
      const currentMessages = instance.getCurrentMessages();
      messages = messages.filter(item => !currentMessages.some(({ id }) => id === item.Id));
      //todo:分页数据少于每页数量或分页数量等于总数量，则表示已经获取所有数据了
      const isEnd = messages.length === total || messages.length < this.messageQueryParam.PageSize;
      if (isEnd) {
        instance.updateContact({ id: contact.id, loadEnd: isEnd });
      }
      //TODO:只有第一次加载的时候才处理发送失败的消息
      if (currentPageIndex === 1) {
        const failedMessageList = this.failedMessageList.filter(item => item.toContactId === contact.id);
        if (failedMessageList.length && messages.length) {
          const lastFailedMessage = failedMessageList[failedMessageList.length - 1];
          const lastNewMessage = messages[0];
          if (lastFailedMessage.sendTime > lastNewMessage.sendTime) {
            instance.updateContact({
              id: contact.id,
              lastContent: lastFailedMessage.content,
              lastSendTime: lastFailedMessage.sendTime,
            });
          }
        }
        messages = messages.concat(failedMessageList);
      }
      next(messages, isEnd);
    },
    handleChangeContact(contact, instance) {
      this.currentChatId = contact.id;
      this.currentContact = contact;
      if (!contact.ResumeId) {
        getResume(contact.id).then(({ Result }) => {
          instance.updateContact({ id: contact.id, ResumeId: Result });
          this.currentContact.ResumeId = Result;
        })
      }
      markRead(contact.id);
      instance.closeDrawer();
      instance.updateContact({ id: contact.id, unread: 0 })
    },
    handleSend(message, next, file) {
      this.sendMessage(message).then(result => {
        const newMessage = { ...result, ...message, id: result.Id, sendTime: new Date(result.CreationTime).getTime(), status: MessageStatusStringEnum[MessageStatusEnum.succeed] };
        IMUI.removeMessage(message.id);
        IMUI.appendMessage(newMessage, true);
        next(newMessage);
      }).catch(_ => next({ status: MessageStatusStringEnum[MessageStatusEnum.failed] }));
    },
    handleMessageClick(event, key, message, instance) {
      //todo:处理发送失败的消息
      if (key === "status") {
        instance.updateMessage({
          id: message.id,
          status: MessageStatusStringEnum[MessageStatusEnum.going]
        });
        this.sendMessage(message).then(result => {
          const originMessage = { ...result, ...message, id: result.Id, sendTime: new Date(result.CreationTime).getTime(), status: MessageStatusStringEnum[MessageStatusEnum.succeed] };
          instance.removeMessage(message.id);
          instance.appendMessage(originMessage, true);
        })
          .catch(error => {
            instance.updateMessage({
              id: message.id,
              status: MessageStatusStringEnum[MessageStatusEnum.failed]
            });
          });
      } else {
        //todo:只有发送成功的消息，点击消息才会处理
        if (message.status === MessageStatusStringEnum[MessageStatusEnum.succeed]) {
          if (message.type == ChatMessageTypeStringEnum[ChatMessageTypeEnum.job]) {
            this.recruitJobId = '';
            setTimeout(() => this.recruitJobId = message.TargetId, 0);
            this.jobDialogVisible = true;
          }
        }
      }
    },
    getMyRecruitJob() {
      getMyRecruitJobList().then(({ Result }) => {
        this.jobOption = Result.map(item => ({
          label: item.Text,
          value: item.Value
        }));
      });
    },
    inviteOrViewDelivery(contact, instance) {
      this.currentContact.ResumeId = undefined;
      if (contact.ResumeId) {
        this.resumeDialogVisible = true;
      } else {
        this.inviteDialogVisible = true;
      }
      this.$nextTick(() => {
        this.currentContact.ResumeId = contact.ResumeId;
      });
    },
    confirmInvite() {
      const message = {
        id: this.currentContact.id + new Date().getTime(),
        type: ChatMessageTypeStringEnum[ChatMessageTypeEnum.job],
        content: `我发布了"${this.inviteJobName}"，快来看一看吧！`,
        status: MessageStatusStringEnum[MessageStatusEnum.going],
        sendTime: new Date().getTime(),
        toContactId: this.currentContact.id,
        fromUser: {
          ...this.user,
        },
        TargetId: this.inviteJobId
      };
      IMUI.appendMessage(message, true);
      this.inviteDialogVisible = false;
      this.sendMessage(message).then(result => {
        const newMessage = { ...result, ...message, id: result.Id, sendTime: new Date(result.CreationTime).getTime(), status: MessageStatusStringEnum[MessageStatusEnum.succeed] };
        IMUI.removeMessage(message.id);
        IMUI.appendMessage(newMessage, true);
      }).catch(_ => IMUI.updateMessage({ id: message.id, status: MessageStatusStringEnum[MessageStatusEnum.failed] }));
    },
    sendMessage(message) {
      return new Promise(async (resolve, reject) => {
        try {
          const param = {
            ChatGroupId: message.toContactId,
            Message: message.content,
            Type: ChatMessageTypeEnum[message.type],

            TargetId: message.TargetId
          };
          const { Result } = await sendMessage(param);
          this.removeFailedMessage(message.id);
          resolve(Result);
        } catch (error) {
          this.addFailedMessage(message);
          reject()
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-chat {
  /deep/.lemon-sidebar {
    width: 350px;
    background: #fff;
    .lemon-contact {
      padding: 14px 14px;
      background: #fff;
      border-bottom: 0.5px solid rgba(179, 171, 171, 0.3);
    }
    .lemon-contact__name,
    .lemon-contact__time {
      font-size: 14px;
    }
    .lemon-contact__content {
      height: 20px;
    }
    .lemon-contact--active {
      background: #f4f4f4;
    }
  }

  /deep/.lemon-messages::-webkit-scrollbar {
    width: 10px;
  }

  /deep/ .lemon-container__title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  /deep/ .lemon-editor {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>

<style lang="scss" scoped>
.page-chat {
  width: 100%;
  > .el-row,
  > .el-row > .el-col {
    height: 100%;
    min-width: 200px;
  }

  .cover {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    i {
      font-size: 100px;
      color: #cccccc;
    }
  }

  .message-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
